.createUser{
    font-family: 'Roboto', sans-serif;
    display: grid;
    grid-template-columns: 14% 86%;
    /* position: relative; */
    color: #000;
    /* width: 100%; */
    height: 100vh;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.createUser-form{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    
}
.createUser-form img{
    width: 17px;
}
.createUser-data{
    display: flex;
    flex-direction: column;
    margin: 1rem 2rem;
}
.createUser-data div{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.createUser-data input, .createUser-data select{
    border: none;
    height: 2rem;
    border: 1.5px solid #1CA0C4;
    outline: none;
    width: 35%;
    font-size: 14px;
    margin-left: 2rem;
    margin-top:10px ;
    /* height: 0px; */
}

.createUser-data label{
    font-weight: 700;
    font-size: 15px;
    line-height: 12px;
}
.createUser-buttons{
    display: flex;
    gap: 1rem;
    margin: 1rem 1rem;
}
.createUser-button-submit{
    background-color: #fff;
    color: #1CA0C4
;
    border: 1px solid #1CA0C4
;
    border-radius: 15px;
    width: 100px;
    height: 30px;
    transition: 0.3s ease-in;
}
.createUser-button-submit:hover{
    background-color: #1CA0C4
;
    color: #fff;
}
.createUser-button-cancel{
    background-color: #fff;
    color: #484847;
    border: 1px solid #484847;
    border-radius: 15px;
    width: 100px;
    height: 30px;
    transition: 0.3s ease-in;
}
.createUser-button-cancel:hover{
    background-color: #484847;
    color: #fff;
}
#generatePassword{
    height: 2rem;
    background-color: #1CA0C4;
    cursor: pointer;
    color: #fff;
    /* border: 2px solid black; */
    border-radius: 16px;
    padding: 1rem;
    font-size: 12px;
}
select{
    -webkit-appearance: none !important; 
    -moz-appearance: none !important; 
    appearance: none !important; 
    padding-left: 0.5rem;
    background-image: url("../../img/dropdownBlue.svg") !important; /* Add default arrow icon */
    background-repeat: no-repeat !important;
    background-position: right 4px center !important; 
    background-size: 12px;
  }