.reportProfile {
  font-family: 'Roboto', sans-serif;
  display: grid;
  grid-template-columns: 14% 86%;
  /* position: relative; */
  color: #000;
  /* width: 100%; */
  height: 100vh;
  width: 100%;
  overflow: hidden;
  overflow-x: hidden;
  /* overflow-y: auto; */
}
.homeRight {
  display: flex;
  flex-direction: column;
}


.report-menu {
  color: #1ca0c4;
  display: flex;
  height: 4rem;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
  border-bottom: 3px dotted #C0C0C0;
}
.report-menu > div {
  border-right: 3px dotted #C0C0C0;
  width: 100%;
  height: 100%;
  font-size: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
  font-weight: bolder;
  cursor: pointer;
}

.reportItems {
  display: flex;
  width: 100%;
  overflow-y: hidden !important;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}

.report-labels {
  border-right: 3px dotted #C0C0C0;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  padding-left: 1rem;
  height: 100vh;
  /* margin-bottom: -10rem; */
  /* align-items: center; */
  width: 100%;
  font-weight: 600;
  margin-top: -4rem;
  color: #000000;
  cursor: pointer;
}

.report-labels > * {
  width: 15rem;
  margin-top: 1rem;
  justify-self: center ;
  align-self: center;
}

/*  */
.report-labels > div > img {
  margin-bottom: -4px;
  padding-right: 0.5rem;
  font: 700;
}
