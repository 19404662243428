.signin {
  background-image: url(../../img/geom1.svg);
  background-position-y: 40rem;
  font-family: 'Lato';
  height: 95vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  overflow: hidden;
  margin: 0;
  /* overflow-y: hidden; */
  /* overflow-x: hidden; */
}
.profileDefault {
  width: 3%;
  min-width: 25px;
}
.signin > p {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 29px;
  text-align: center;
}
.waves-cont {
  background-image: url(../../img/waves_signin.svg);
  width: 100%;
  height: 13% !important;
  background-size: cover;
  /* background-position: center; */
  background-repeat: no-repeat;
  position: absolute;
  left: 50%;
  bottom: 0;
  /* margin-top: 1rem; */
  transform: translate(-50%, 0%);
}
.tenie-logo-signin {
  width: 15%;
  min-width: 120px;
  margin-bottom: 1rem;
}
.loginForm {
  /* border: 2px solid black; */
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  margin: 0rem auto 3rem;
  margin-bottom: 1rem;
}
.loginForm-data {
  /*  width: 100%; */
  width: 40%;
  max-width: 500px;
  height: 45px;
  outline: none;
  background-color: #f5f5f5;
  border: 1px solid #d9d9d9;
  border-radius: 7px;
  font-size: 17;
  padding: 10px;
  margin: 0.5rem 1rem;
}
.loginForm-button {
  font-weight: 700;
  font-size: 15px;
  line-height: 29px;
  width: 40%;
  max-width: 500px;
  height: 45px;
  color: #ffffff;
  background: #1ca0c4;
  border: 1px solid #d9d9d9;
  border-radius: 7px;
  margin-top: 30px;
  margin: 1rem;
}
.loginForm-button:hover {
  background: #047595;
  cursor: pointer;
}
.forgot-password {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 29px;
  text-align: center;
  margin-bottom: 0;
  color: #1ca0c4;
}
.forgot-password:hover {
  cursor: pointer;
  text-decoration: underline;
}

.signin-already-account {
  font-weight: 600;
  font-size: 13px;
  /* margin-: 4rem; */
  /* display: none; */
  /* line-height: 22px; */
  /* align-self: flex-end; */
}
.signin-already-account > a {
  color: #1ca0c4;
  text-decoration: none;
}
.signin-right {
  margin-top: -1.5rem;
  margin-bottom: 8rem;
  margin-right: 1rem;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  align-self: flex-end;
}
