.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  width: 100%;
  background: #1ca0c4;
  color: #ffffff;
  height: 56px;
  font-size: 18px;
  font-weight: bolder;
  /* position: fixed; */
  /* z-index: 0; */
}
.nav-left{
  display: flex;  
}
.addIcon {
  width: 22px;
  margin-right: 0.8rem;
}
.addIcon:hover {
  cursor: pointer;
}
.navbar .navbar-profile {
  /* margin-left: 60%; */
  display: flex;
  align-items: center;
  /* width: 160px; */
  display: flex;
  justify-content: space-between;
  margin-right: 1.5%;
}
.navbar-logout-button {
  width: 70px;
  height: 21px;
  font-size: 11px;
  background: #ffffff;
  border-radius: 20px;
  border: none;
  color: black;
}
.navbar-logout-button:hover {
  background: rgb(241, 239, 239);
}
.navbar-profile-button {
  background: #6f6e6e;
  border: 1px solid white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  color: #fff;
  font-size: 12px;
}

.addModal-closebtn {
  padding: 0.4rem;
  position: absolute;
  top: -1.4rem;
  right: 0;
}
.addModal {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 10%;
  padding-bottom: 10%;
  gap: 4rem;
}
.addModal > div {
  display: flex;
  gap: 2rem;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}
.addModal-type {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 2rem;
  margin-bottom: 0;
  padding-bottom: 0;
}
.addModal-type:hover {
  cursor: pointer;
  background: #f1f1f1;
  border-radius: 5px;
}
.nav-companyBox {
  /* border: 2px solid black; */
  display: flex;
  flex-direction: column;
}
.nav-companyBox > p {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-companyBox > div {
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  margin: 0;
  border: 1px solid #fbfafa;
  border-radius: 12px;
}

.nav-companyLife {
  font-size: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 1.5rem;
  background: #1ca0c4;
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.nav-companyLife-outer {
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  align-items: center;
  width: 3rem;
  height: 2rem;
  background: #d9d9d9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
