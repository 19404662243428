.home {
    font-family: 'Roboto', sans-serif;
    display: grid;
    grid-template-columns: 14% 88%;
    /* position: relative; */
    color: #000;
    /* width: 100%; */
    height: 100vh;
    width: 100%;
   
    /* overflow-x:hidden;
    overflow-y:hidden; */
    overflow: hidden;
   
  }

.homeRight{
    display: flex;
    flex-direction: column;
}
  
.searchbar{
  /* position: absolute; */
    width: 310px;
    padding-left: 1rem;
    padding-right: 0;
    height: 30px;
    border-radius: 8px;
    align-self: center;
    margin-top: 1rem;
    /* margin-bottom: 1rem; */
    /* margin-left: 60%; */
    border: 1px solid #000000;
    background: #FBFAFA;
}
.searchbar img{
  position:  relative;
  top: 5px;
  height: 70%;
  margin-right: 8px;
}
.searchbar input{
  margin: 0;
  width: 230px;
  position: relative;
  bottom: 1px;
  border: none;
  outline: none;
  background: #FBFAFA;
}
.asset-function-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 1rem;
  color: #ACABAB;
  margin-bottom: 0;
}
.assetFilter{
  display: flex;
  align-items: center;
  gap: 1rem;
}
.assetFilter button{
  margin: 0;
  /* padding: 0.3rem !important; */
}
.assetFilter button:hover{
  background-color:  #cf6502;
  color: #fff;
  border: 1px solid white;
}
.assetFilter select{
  border: solid #6c6868 1px;
  height: 24px;
  border-radius: 5px;
  outline: none; 
}
.home-header-button{
  margin: 0;
  padding: 0.3rem 0.5rem;
  /* border-radius: 15px; */
}

.print-export{
  display: flex;
  /* justify-content: flex-end; */
  align-items: center;
  padding-right: 1rem;
  gap: 1.5rem;
  margin: 0.5rem 1rem 0.5rem;
  color: #ACABAB;
}
.print-export img{
  width: 16px;
  margin: 0 4px;
}
.export img{
  width: 11px;
}

.overflow-table{
  overflow-x: auto;
}


.assetTable{
  /* margin-top: 1rem; */
  font-size: 14px;
  width: 100%;
  border-collapse: collapse;
  /* overflow-x: auto; */
}
.assetTable img{
  width:13px;
  margin-left: 4px;
}
.assetTable tr{
  /* border: 2px solid blanchedalmond; */
  /* padding: 0.6rem; */
  margin: 0;
  height: 38px;
  /* min-height: 30px; */
}
.assetTable tr th{
  /* padding: 0.9rem 0; */
  /* padding: 0.9rem 1.5rem 0.9rem 0.3rem; */
  /* padding-right: 2rem; */
  background: #E4E3E3;
  /* border: none; */
  font-size: 12px;
  /* border: 2px solid black; */
  text-align: start;
}
.assetTable tr td{
  border: none;
  /* border: 2px solid black;   */

}
.assetTable tr:nth-child(even){
  background: #FBFAFA;
}
.assetTable tr:nth-child(odd){
  background: #E4E3E3;
}
.view-asset-header{
  margin-bottom: 0rem;
}
.view-asset-label{
  font-size: 0.8rem;
  font-weight: bold;
  margin-bottom: 0;
}
.view-asset-text{
  font-size: 1rem;
  margin-top: 4px;
}
.view-button{
/* height: 22px; */
padding: 0.3rem 0.3rem;
border-radius: 12px;
color: #FFFFFF;
background: #E4842C;
border: 1px solid #D9D9D9;
font-weight: 400;
font-size: 12px;
/* line-height: 9px; */
width: 60px;
min-width: 40px;
margin: 0;
}
.addOrangeSVG{
  align-self: center;
  width: 20px;
    margin-left: 4px;
}

.add-asset-home{
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding: 0.3rem 0.5rem;
}

.add-asset-home span{
  /* margin-bottom: 4px; */
  text-decoration: none;
}
th{
  font-size: 12px;
}
