.userProfile {
	font-family: "Roboto", sans-serif;
	display: grid;
	grid-template-columns: 14% 86%;
	color: #000;
	height: 100vh;
	width: 100%;
	overflow-x: hidden;
	overflow-y: auto;
}
.homeRight {
	display: flex;
	flex-direction: column;
}
.titleBar {
	font-family: Roboto Serif;
	background-color: #FCFCFC;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	height: 5rem;
}
.titleBar > div {
	display: flex;
	justify-content: flex-start;
	align-items: center; 
	font-size: 13px;
	width: 10rem;
	font-weight: 700;
	line-height: 28px;
	color: #1CA0C4;
    /* border: 2px solid black;	 */
}
.titleBar > .containIcons{
	justify-content: center;
}
.row{

    font-family: Roboto Serif;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	/* border: 2px solid black; */
}
.row > div{  
	display: flex;
	justify-content: flex-start;
	align-items: center; 
	font-size: 13px;
	width: 10rem;
	height: 2rem;
	/* border: 2px solid black; */
}
.row > .containIcons{
	cursor: pointer;
	justify-content: center;
}
.odd{
	background-color: #F9F9F9;
}
.even{
	background-color: #FCFCFC;

}