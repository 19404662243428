.otherSettings{
    font-family: 'Roboto', sans-serif;
    display: grid;
    grid-template-columns: 14% 86%;
    /* position: relative; */
    color: #000;
    /* width: 100%; */
    height: 100vh;
    width: 100%;
    /* overflow: hidden; */
    overflow-x: hidden;
    /* overflow-y: scroll; */
}
.header{
    color: black;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    background: #FBFAFA;
}
.settings{
    margin-top: 4rem;
    margin-left:7rem;
    height: 6rem;
    width: 75vw;
    align-items: center;
    justify-content: space-between;
    display:flex;
    /* border: 2px solid black; */
}
.setting-name{
    display: flex;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    width: 14rem;
    height: 57px;
    background: #1CA0C4;
    color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
}
.select{
    -webkit-appearance: none; 
    -moz-appearance: none; 
    appearance: none; 
    background-image: url("../../img/dropdownBlue.svg"); /* Add default arrow icon */
    background-repeat: no-repeat;
    background-position: right 10px center; 
    background-size: 12px 12px; 
    font-weight: 300;
    padding-left: 4rem;
    font-size: large;
    width: 14rem;
    height: 57px;
    margin-right: 1rem;
    margin-left: 4rem;
    border: 1px solid #D9D9D9;
    border-radius: 30px;
}

.desc{
    font-family: 'Lato';
    width: 50rem;
    display: flex;
    align-items: flex-start;
    justify-content: left;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    margin-left: 2rem;
    margin-right: 8rem;

}

#dateFormat{
    font-size: medium;
    padding-left:2.5rem;
}
#numberFormat{

    padding-left: 2rem;
}

/* For screen widths less than 1200px */
@media (max-width: 1300px) {
    .setting-name {
        font-size: 14px;
        width: 18rem;
    }

    .select {
        width: 18rem;
        font-size: 14px;
    }

    .desc {
        font-size: 16px;
    }
}


