.userManagement{
    font-family: 'Roboto', sans-serif;
    display: grid;
    grid-template-columns: 14% 86%;
    /* position: relative; */
    color: #000;
    /* width: 100%; */
    height: 100vh;
    width: 100%;
    /* overflow: hidden; */
    overflow-x: hidden;
    overflow-y: auto;
}
.accessBar{
    height: 39px;
    background: #E9E9E9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:1.5rem 2rem;
    
}
.accessBar div{
    /* width: 51px; */
    

    color: white;
    margin: 0 1rem;
    /* padding: 0 0.4rem; */
    display: flex;
    justify-content: center;
    gap: 1rem;
    font-size: 12px;

}
.accessBar div div{
    /* height: 19px; */
    padding: 0.4rem;
    background: #484847;
}
.accessBar div div img{
    margin-left: 1rem;
    width: 12px;
    color: white;
    
}
.analytics{
    padding: 0.4rem;
    background: #484847;
}
.accessBar-delete-btn{
    /* width: 70px; */
    /* height: 23px; */
    background: #1CA0C4
;
    border: 1px solid #FFFFFF;
    border-radius: 15px;
    padding: 0.5rem 0.5rem;
    font-size: 14px;
    line-height: 11px;
}
.userTable{
    margin: 0;
    padding: 0;
    font-size: 14px;
    width: 100%;
    border-collapse: collapse;
    /* padding: 1rem; */
}
.userTable tr:first-child{
    height: 50px;
    font-weight: 600;
    font-size: 14px;
    line-height: 11px;
    background: #FCFCFC;
    text-align: left;
  
    /* padding-left: 0; */
}
.userTable tr:nth-child(even){
    background: #fbfafa;
  }
.userTable tr:nth-child(odd){
    background: #E9E9E9;
  }

.userTable-row-header th{
    /* margin-left: 2rem; */
    
}
.userTable tr td{
    padding-left:1rem;
    height: 40px;
    text-align: left;
    font-weight: 400;
    font-size: 12px;
}
.userTable tr th {
    padding-left:1rem;
}

.userTable-edit{
    /* height: 22px; */
    padding: 0.3rem 0.4rem;
    background: #23D335;
    border-radius: 12px;
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    /* line-height: 9px; */
    width: 60px;
    min-width: 40px;
    margin: 0;
}
.userTable-edit:hover{
    background: #1caa2a;
}
.userTable-delete{
    /* height: 22px; */
    background:  #F42416;
    border-radius: 12px;
    /* color: #000; */
    font-weight: 400;
    font-size: 12px;
    /* line-height: 9px; */
    width: 60px;
    min-width: 40px;
    padding: 0.3rem 0.4rem;
    margin: 0;
}
.userTable-delete:hover{
    background: #cc1b12;
}

