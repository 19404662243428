.signup {
  font-family: 'Lato', sans-serif;
  display: grid;
  grid-template-columns: 50% 50%;
  /* position: relative; */
  color: #000;
  /* width: 100%; */
  height: 100vh;
  width: 100%;
  /* overflow-x:hidden;
    overflow-y:hidden; */
  overflow: hidden;
  /* padding: 1rem; */
}
.signupLeft {
  background: #eaeaea;
  /* height: 100vh; */
  width: 100%;
  position: relative;
  height: 100vh;
}

.tenie-logo {
  position: absolute;
  object-fit: contain;
  /* display: block; */

  /* max-width: 100%; */
  /* max-height: 68px; */
  /* max-width: 150px; */
  width: 25%;
  left: 5%;
  top: 2%;
  transform: translate(-5%, -2%);
}
@media screen and (max-width: 700px) {
  .tenie-logo {
    width: 40%;
  }
}
.singup-left-img {
  position: absolute;
  /* max-width: 427px; */
  object-fit: contain;
  display: block;
  width: 57%;

  /* max-width: 500px;
    max-height: 400px; */

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.waves-cont {
  background-image: url(../../img/waves.svg);
  width: 100%;
  height: 10%;
  background-size: cover;
  /* background-position: center; */
  background-repeat: no-repeat;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0%);
}

.signupRight {
  background-image: url(../../img/geom.svg);
  font-family: Lato;
  height: 100vh;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  /* gap: 2rem; */
}
.signup-top.right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
}
.signup-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}
.already-account {
  font-weight: 600;
  font-size: 13px;
  /* line-height: 22px; */
  /* align-self: flex-end; */
}
.already-account.right {
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  align-self: flex-end;
}
.already-account > a,
.go-back {
  font-weight: 700;
  color: #1ca0c4;
  text-decoration: none;
}
.go-back:hover {
  cursor: pointer;
}
.signupForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 2rem auto;
}
.phone-number {
  position: relative;
  width: 100%;
  align-self: center;
  margin: 5px auto;
  /* width: 100%; */
  display: flex;
  justify-content: center;
  padding: 2px 20%;
}
/* .phone-number input{
  width: 100px;
} */
.signupForm-data {
  padding: 10px;
  margin: 10px auto;
  /* width: 360px; */
  width: 60%;
  height: 45px;
  outline: none;
  background-color: #f5f5f5;
  border: 1px solid #d9d9d9;
  /* border: 2px solid black; */
  border-radius: 4px;
  font-size: 17;
}
.signupForm-button {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 29px;
  width: 60%;
  height: 45px;
  color: #ffffff;
  background: #1ca0c4;
  border: 1px solid #4fd6fc;
  border-radius: 7px;
  margin-top: 30px;
  margin: 1rem;
}
.signupForm-button.inactive {
  background: #7addf8;
}
.signupForm-button.inactive:hover {
  background: #4fd6fc;
  cursor: auto;
}
.signupForm-button:hover {
  background: #047595;
  cursor: pointer;
}

label > a {
  color: black;
  font-weight: 700;
}
.policy {
  display: flex;
  align-items: flex-start;
  justify-content: start;
  width: 60%;
  margin: 1rem 1rem;
}
.invalid-feedback {
  font-size: 12px;
  color: red;
  margin-bottom: 5px;
  margin-top: 0;
  max-width: 280px;
  align-self: flex-start;
  margin-left: 30%;
}
/* label { display: inline-block; width: 360px; } */
