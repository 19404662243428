.print-export2 {
  background: #e9e9e9;
  padding: 2rem;
  margin: 0 !important;
  height: 39px;
  display: flex;
  justify-content: space-between !important;
}

.print-export2 div {
  display: flex;
  gap: 1rem;
}
.employeesManagement {
  min-width: 700px;
}
.employeesManagement table {
  margin: 0;
}
.print-export2 button,
.custom-file-label {
  font-style: normal;
  font-weight: 700;
  color: #ffffff;
  background: #1ca0c4;
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  margin-top: 30px;
  margin: 1rem;
  margin-left: 0;
  margin-right: 0;
  padding: 0.3rem 0.5rem;
  font-size: 14px;
}
.custom-file-label:hover {
  cursor: pointer;
}
.addEmpModal {
  position: relative;
}
.employeesManagement.addModal-closebtn {
  padding: 0.4rem;
  position: absolute;
  top: -1.4rem;
  right: 0;
}

.noEmpData {
  margin: 1rem;
  color: red;
}
.sort-btn {
  width: 1rem;
  margin: 0 2px;
}
.sort-btn:hover {
  cursor: pointer;
  /* background-color: #6f6767; */
  scale: 1.2;
}
.emp-table {
  text-overflow: ellipsis !important;
  table-layout: fixed I !important;
  /* width: 40 !important; */
}
.emp-table tr {
  /* border: 1px solid; */
  border-collapse: collapse;
  /* min-height: 40px; */
  /* height: fit-content !important; */
  text-overflow: ellipsis;
}

.emp-table-body td,
.emp-table-body th {
  border: none;
  max-width: 10px;
  white-space: nowrap;
  /* height: fit-content; */
  overflow: hidden;
  /* width: 110px; */
  /* border-right: 1px solid #CFCECE; */
  min-width: 70px;
}
.deleteModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5%;
  text-align: center;
}
.deleteModal-btn {
  display: flex;
  gap: 2rem;
  margin: 2rem 2rem;
}
.deleteModal-btn button {
  width: 5rem;
  height: 2.3rem;
}
#wrapper {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.swal2-popup {
  border-radius: 20px !important; /* Adjust the border-radius value as per your desired curve */
}
.swal2-title {
  margin: 0;
  padding: 0;
  /* border: 2px solid black; */
}

.modalNav {
  height: 45px;
  background: #1ca0c4;
  display: flex;
  color: white;
  align-items: center;
  padding-left: 2rem;
  font-size: 22px;
  font-weight: bolder;
  /* border: 2px solid black; */
}
.createUser-data > div > label {
  font-weight: bolder;
}
