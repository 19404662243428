.instanceProfile {

	font-family: "Roboto", sans-serif;
	display: grid;
	grid-template-columns: 14% 120%;
	color: #000;
	height: 100vh;
	width: 100%;
	overflow-x: auto;
	overflow-y: auto;
}
.homeRight {
	display: flex;
	flex-direction: column;
}
.instancetitleBar {

	font-family: Roboto Serif;
	background-color: #FCFCFC;
	display: flex;
	/* justify-content: space-evenly; */
	align-items: center;
	height: 5rem;
}
.instancetitleBar > div {
	/* border: 2px solid black; */
    margin-left: 1rem;
    margin-right: 1rem;
	font-size: 13px;
	display: flex;
	justify-content: flex-start;
	align-items: center; 
    padding-left: 1rem;
    padding-right: 1rem;
	font-weight: 700;
	line-height: 28px;
	color: #1CA0C4;
	width: 10rem;
	height: 2rem;
}
#sNo{
	width: 7rem;
}
.instancetitleBar > .instancecontainIcons{
	justify-content: center;
}
.row{

    font-family: Roboto Serif;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	/* border: 2px solid black; */
}
.row > div{  
	display: flex;
	margin-left: 1rem;
    margin-right: 1rem;
	/* margin-top: 1rem; */
	font-size: 13px;
	padding-left: 1rem;
    padding-right: 1rem;
	justify-content: flex-start;
	align-items: center; 
	width: 10	rem;
	height: 2rem;
	/* border: 2px solid black; */
}
#sNoData{
	width: 7rem;
}
.row > .containIcons{
	cursor: pointer;
	justify-content: center;
}
.odd{
	background-color: #F9F9F9;
}
.even{
	background-color: #FCFCFC;

}
#instanceIdTitle{
	width: 14rem;
}
#instanceId{
	width: 14rem;
}