label{
    font-weight: bold;
}
.companyProfile{
    font-family: 'Roboto', sans-serif;
    display: grid;
    grid-template-columns: 14% 86%;
    /* position: relative; */
    color: #000;
    /* width: 100%; */
    height: 100vh;
    width: 100%;
    /* overflow: hidden; */
    overflow-x: hidden;
    overflow-y: auto;
}
.country, .state{
    -webkit-appearance: none; 
    -moz-appearance: none; 
    appearance: none; 
    background-image: url("../../img/dropdownBlue.svg"); /* Add default arrow icon */
    background-repeat: no-repeat;
    background-position: right 10px center; 
    background-size: 13px 13px; 
}
.companyForm{
    font-size: 14px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 1rem;
    margin: 0.5rem;
    column-gap: 4rem;
    
}
.companyForm-inputCont{
    font-size: 13px;
    width: 40%;
    padding: 0.2rem;
    margin-left: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border: 2px solid black; */
    /* column-gap: 1rem; */
}
.companyForm-inputCont label{
    margin-bottom: 1rem;
}
.companyForm-inputCont input,.companyForm-inputCont textarea,.companyForm-inputCont .country, .companyForm-inputCont .state{
    /* padding: 10px; */
    margin: 1rem 0;
    margin-left: 1rem;
    margin-right: 1rem;
    padding: 3px;
    /* width: 360px; */
    width: 60%;
    height: 32px;
    outline: none;
    border: 1px solid #1CA0C4;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
    border-radius: 7px;
    /* font-size: 100px; */
}
.companyForm-inputCont textarea{
    height: 58px;
}
.companyForm-submitButton{
    height: 25px;
    width: 82px;
    padding: 0;
    border: 1px solid #fff;
    border-radius: 20px;
    align-self: center;
    font-size: 10px;
}
.company-header{
    font-size: 14px;
    display: flex;
    align-items: center;
    height: 3rem;
    background: #e4e3e3;
    padding-left: 1.8rem;
    margin: 0;
    font-weight: bolder;
}
.companyForm-inputCont.image input{
    border: 2px solid black;
    width: 30%;
    /* position: absolute; */
    height: 50px;
    /* padding: 2rem; */
    /* border: none; */
    /* outline: none; */
    /* width: 82px; */
    
    /* height: 30px; */
    font-size: 10px;
}
.companyForm-inputCont.image img{
    width: 30%;
    border: 1px solid black;
    margin-right: 1rem;
}
.companyForm-inputCont.image img .close{
    display: hidden;
    width: 0;
}
.imageUpload-button{
    width: 82px;
    padding: 0;
    height: 30px;
    font-size: 10px;
    font-weight: lighter;
}
.company-buttons{
    display: flex;
    gap: 1rem;
    margin: 1rem 1rem;
}
.company-button-submit{
    background-color:#1CA0C4;
    color: white;
    border: 1px solid#1CA0C4;
    border-radius: 15px;
    transition: 0.3s ease-in;
    width: 8rem;
    padding: 0.5rem 1rem;
}
.company-button-submit:hover{
    background-color: #ffffff;
    color: #1CA0C4;
}
.company-button-submit-save{
    background-color: #fff;
    color: #00AB66;
    border: 1px solid #00AB66;
    border-radius: 15px;
    transition: 0.3s ease-in;
    width: 8rem;
    padding: 0.5rem 1rem;
}
.company-button-submit-save:hover{
    background-color: #00AB66;
    color: #fff;
}
.company-button-changeOwner{
    background-color: #fff;
    color: #fff;
    background-color: #1CA0C4;
    border-radius: 15px;
    /* width: 100px; */
    /* height: 30px; */
    padding: 0.5rem 1rem;
    width: 8rem;
    transition: 0.3s ease-in;
}
.company-button-changeOwner:hover{
    border: 1px solid #1CA0C4;
    background: none;
    color: #1CA0C4;
}
.company-selectModal{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.company-selectModal>select{
    width: 80%;
    height: 2rem;
}
.company-selectModal-btn{
    width: 4rem;
    height: 2rem;
    margin-top: 3rem;
}
.companyModal-closebtn{
padding: 0.4rem;
  position: absolute;
  top: -0.5rem;
  width: 6rem;
  right: 1rem;
}

.cf-flex{

    margin-right: 6rem;
    display:flex;
    justify-content: space-between;
}
