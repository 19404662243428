.computerForm{
    /* overflow-x: auto;
    width: 100%; */
}
.formSection{
    background:#F2F2F2;
    padding: 1rem 6rem;
    font-family: 'Roboto', 'serif';
    margin-bottom: 1rem;
    
}
.formSection-header{
    display: flex;
    align-items: center;
    padding-left: 3px;
    font-size: 17px;
    margin-bottom: 1rem;
    /* border-left:3px solid #1CA0C4 */
 ;
    font-weight: 600;
}
.formSection-header img{
    width: 15px;
    margin-left: 2px;
}
.formSection-header .addOrangeSVG{
    width: 20px;
    margin-left: 4px;
}
.formSection-header .addOrangeSVG:hover{
    cursor: pointer;
    width: 21px;
}
.formSection-one{
    margin:1rem 0 ;
    display: flex;
    justify-content: space-between;
    
}
.formSection-one div{
    display: flex;
    flex-direction: column;
}
.formSection-one div label{
    font-size: 12px;
    margin-bottom: 4px;
    font-weight: 600;
    font-family: 'Roboto';
}
.formSection-one div textarea{
    border:  2px solid #1CA0C4;
    width: 250px;
    height: 60px;
}
.formSection-one div input{
    border:  1px solid #1CA0C4;
    width: 140px;
    height: 30px;
}
.formSection-one select{
    border:  2px solid #1CA0C4;
    width: 128px;
    height: 27px;
}
.formModal{
    padding: 1rem 10%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.formModal h2{
    margin: 0;
}
.formSection-one.two{
    display: flex;
    justify-content: flex-start;
    /* justify-content: left; */
    gap: 33%;
    
}
.formSection-one.two div{
    /* margin-right: 25%; */
}
.grid {
    display: grid;  
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    column-gap: 2rem;
    
}

.modal-buttons{
    align-self: flex-end;
}
.modal-buttons button{
    padding: 0.4rem;
}
.saveModal-button{
    background: #D2FF88;
    color: black;
}
.saveModal-button:hover{
    background: #b2f14b;
}
.computerForm-row-header{
    background: #1CA0C4
;

}
.formButtonSection{
    padding-right: 2rem;
    display: flex;
    width: 100%;
    justify-content: right;
}
.formButtonSection .save{
    padding: 0.3rem 1rem;
    border: 1px solid #1CA0C4
;
    border-radius: 22px;
}
.formButtonSection .cancel{
    padding: 0.3rem 1rem; 
    background: #6F6E6E;
    border: 1px solid #6F6E6E;
    border-radius: 22px;
}
.formButtonSection .cancel:hover{
    background: #5a5959;
}
.formSection-one.table{
    overflow-x: auto;
    /* min-width: 600px; */
}
.computerForm-table{
    min-width: 600px;
}
.computerForm-table tr:nth-child(odd){
    background: #C8C7C7 !important;
}
.computerForm-table tr{
    /* background: #C8C7C7 !important; */
    text-align: center;
}

