.acdr-reportProfile {
    font-family: 'Roboto', sans-serif;
    display: grid;
    grid-template-columns: 14% 86%;
    /* position: relative; */
    color: #1CA0C4;
    /* width: 100%; */
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    width: 100% ;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .acdr-homeRight {
    
    display: flex;
    flex-direction: column;
  }
  .acdr-ReportDetailsProfile {
    
    font-family: 'Roboto', sans-serif;
    display: grid;
    grid-template-columns: 14% 86%;
    /* position: relative; */
    color: #000;
    /* width: 100%; */
    height: 100vh;
    width: 100%;
  }
  .acdr-sort-btn{
    cursor: pointer;
  }
  .acdr-pageHeader {
    
    display: flex;
    align-items: center;
    /* border: 2px solid black; */
    background: #fcfcfc;
    height: 3rem;
  }
  .acdr-dropdown {
    padding: 1rem;
  }
  .acdr-dropbtn {
    margin-left: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1CA0C4;
    color: #ffffff;
    padding-right: 1.5rem;
    border-radius: 20px;
    width: 10rem;
    height: 2rem;
    font-size: 14px;
    cursor: pointer;
  }
  .acdr-dropbtn > img {
    /* margin-bottom: -4px; */
    padding: 4px;
    margin-left: 10px;
  }
  .acdr-dropdown-content {
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  }
  
  .acdr-dropdown:hover .acdr-dropdown-content {
    display: flex;
    flex-direction: column;
  }
  
  .acdr-dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .acdr-dropdown-content a:hover {
    background-color: #ddd;
  }
  .acdr-customize {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
    color: #1CA0C4;
  }
  
  .acdr-customize > img {
    margin: 10px;
  }
  .acdr-printOrExport {
    display: flex;
    width: 90%;
    justify-content: flex-end;
    color: #1CA0C4;
  }
  .acdr-printOrExport > div {
    
    padding: 0.5rem;
    display: flex;
    cursor: pointer;
    margin: 0.4rem;
  }
  .acdr-printOrExport > div > img {
    margin-right: 10px;
  }
  .acdr-headline {
    font-weight: 900;
    font-size: large;
    line-height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fcfcfc;
    background:#71797E;
    height: 7rem;
  }
  .acdrTable {
    display: flex;
    overflow-x: auto;
    /* height: 70%; */
    width: 100%;
  }
  
  .acdr-colHeader {
    /* border: 2px solid black; */
    /* background-color: #E4E3E3; */
    color: #000;
    font-size: 14px;
    font-weight: bolder;
    padding-bottom: 1.5rem;
    padding-top: 1rem;
    /* display: flex; */
    /* justify-content: center; */
    padding-left: 1rem;
    /* margin-bottom: 2rem; */
    /* margin-top: 1rem; */
    /* margin-left: 1rem; */
  }
  .acdr-ColContent{
    max-width: 100%;
    width: 202px;
    min-width: 202px;
    max-width: 202px;
    display: flex;
    flex-direction: column;
  }
  .acdr-colBody {
    margin-left: 1rem;
    padding:1rem;
    width: 202px;
    min-width: 202px;
    max-width: 202px;
    /* margin-top: 1rem; */
    height: 3rem;
    color: black;
  }
  .acdr-vr {
    overflow: hidden !important;
    border-right: 1px solid #cfcece;
    height: 80vh;
    z-index: 100;
  }
  .acdr-pagination-controls {
    z-index: 100;
    margin-top: -4rem;
    display: flex;
  
    justify-content: end;
  }
  .acdr-inner-div-pageController {
    padding-left: 2rem;
    padding-right: 2rem;
    background-color: #fff;
    margin-right: 1rem;
    box-shadow: 2px 2px 2px 2px #4f4e4e;
    width: 30rem;
  }
  #acdr-snoCol{
    min-width: 100px;
    width: 4rem;
  }
  
  
  
  #acdr-height{
    margin-bottom: -18rem;
    position: absolute;
  }
  
  .BG2 {
    /* border: 2px solid black; */
    background: #FBFAFA;
    width: 220% !important;
    margin-left: 0;
  }
  .BG1 {
    margin-left: 0;
    background: #E4E3E3 !important;
    width: 220%;
  }
  .yearSecret{
    display: flex;
    align-items: center;
    width: 108%;
    margin-left: -1rem;
    margin-top: -1.2rem;
    justify-content: flex-end ;
    border-bottom: 2px solid #CFCECE;
  }
  .yearSecret2{
    display: flex;
    align-items: center;
    width: 108%;
    margin-left: -1rem;
    margin-top: -1.2rem;
    justify-content: flex-start ;
    border-bottom: 2px solid #CFCECE;
  }

  .capitalServiceCosts{
    display: flex;
    align-items: center;
    justify-content: center;

  }