.dataField-container {
  display: flex;
  /* gap: 4rem; */
  /* width: 100%; */
  justify-content: space-between;
}
.dataField-container .createUser-data {
  width: 100%;
}
.dataField-container .createUser-data input,
.dataField-container .createUser-data select {
  width: 74%;
}
.createUser-form .addCategory {
  flex-direction: column;
  /* height: 100vh; */
  display: flex;
}
.createUser-buttons.categoryButtons {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  row-gap: 4rem;
  /* align-items: flex-end; */
}
.createUser-buttons.categoryButtons div {
  gap: 2rem;
}
.show-more {
  text-decoration: underline;
  color: blue;
  font-weight: bold;
}
.show-more:hover {
  cursor: pointer;
}
.userTable-row-header {
  background-color: #e4e3e3 !important;
}

.userTable tr:nth-child(even) {
  background: #fbfafa;
}
.userTable tr:nth-child(odd) {
  background: #e4e3e3;
}

select{
  -webkit-appearance: none !important; 
  -moz-appearance: none !important; 
  appearance: none !important; 
  padding-left: 0.5rem;
  background-image: url("../../img/dropdownBlue.svg") !important; /* Add default arrow icon */
  background-repeat: no-repeat !important;
  background-position: right 4px center !important; 
  background-size: 12px;
}