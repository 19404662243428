* {
  text-overflow: ellipsis;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  /* font-family: 'Roboto', sans-serif !important; */
}

html {
  height: 100%;
  width: 100%;
  font-size: 16px;

}

body {
  height: 100%;
  width: 100%;
  margin: 0px;
}
button{
    font-style: normal;
    font-weight: 700;
    color: #FFFFFF;
    background: #1CA0C4
;
    border: 1px solid #D9D9D9;
    border-radius: 7px;
    margin-top: 30px;
    margin: 1rem;
    margin-right: 0;
}

button:hover{
  background: #1CA0C4;
    cursor: pointer;
}
.sidebar-container{
    font-family: 'Roboto', sans-serif;
    display: grid;
    grid-template-columns: 14% 86%;
    /* position: relative; */
    color: #000;
    /* width: 100%; */
    height: 100vh;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

#root {
  height: 100%;
  width: 100%;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #AAA9A9;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #AAA9A9;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #AAA9A9;
}
.unautorizedAccess{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  font-size: 1.5rem;
  font-weight: bolder;
  overflow-y: hidden;
}
.swal2-popup {
  border-radius: 20px !important; /* Adjust the border-radius value as per your desired curve */
}
.swal2-title {
  margin: 0;
  padding: 0;
  /* border: 2px solid black; */
}

  