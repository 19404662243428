.roles-header{
    background: #FFFFFF;
    padding-left: 2rem;
    margin: 0 !important;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .roles-header>p{
    color: black;
    font-size: 1rem;
    line-height: 13px;
    font-weight: 700;
  }
  .roles-header div{
    display: flex;
    gap: 1rem;
    
  }

  .roles{
    min-width: 700px;
  }
  .roles table{
      margin: 0;
      text-align: left;
     
  }
  .roles table th{
    /* padding-left: 2rem ; */
    /* padding-bottom: 0; */
    background: #F9F9F9; 
    /* line-height: 2rem; */
    /* margin: 0; */
    /* padding: 1rem; */
  }

  .roles-table-body td, .rolesheader th{
    border: none;
    padding: 1rem !important;
    padding-left: 2rem !important;
    text-align: justify ;
    min-width:70px;
    /* background: #E9E9E9; */
  }
  .roles-table tr:nth-child(even){
    background: #fbfafa;
  }
  .roles-table tr:nth-child(odd){
    background: #E4E3E3;
  }
  .emp-table{
    text-overflow:ellipsis !important;
    table-layout: fixed I !important;
    /* width: 40 !important; */
  }
  .emp-table tr{
    /* border: 1px solid; */
    border-collapse: collapse;
    /* min-height: 40px; */
   /* height: fit-content !important; */
   text-overflow:ellipsis
  }
  
  .emp-table-body td, .emp-table-body th{
    border: none;
    max-width: 10px;
    white-space: nowrap ;
    /* height: fit-content; */
    overflow: hidden;
      /* width: 110px; */
    /* border-right: 1px solid #CFCECE; */
    min-width:70px;
  }
  .deleteModal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5%;    
    text-align: center;  
  }
 
  #wrapper {
    margin-left:auto;
    margin-right:auto;
    width:100%;
  }