.da-reportProfile {
  font-family: 'Roboto', sans-serif;
  display: grid;
  grid-template-columns: 14% 86%;
  /* position: relative; */
  color: #1CA0C4;
  /* width: 100%; */
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  width: 100% ;
  overflow-x: hidden;
  overflow-y: hidden;
}
.da-homeRight {
  
  display: flex;
  flex-direction: column;
}
.da-ReportDetailsProfile {
  
  font-family: 'Roboto', sans-serif;
  display: grid;
  grid-template-columns: 14% 86%;
  /* position: relative; */
  color: #000;
  /* width: 100%; */
  height: 100vh;
  width: 100%;
}
.da-sort-btn{
  cursor: pointer;
}
.da-pageHeader {
  
  display: flex;
  align-items: center;
  /* border: 2px solid black; */
  background: #fcfcfc;
  height: 3rem;
}
.da-dropdown {
  padding: 1rem;
}
.da-dropbtn {
  margin-left: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1CA0C4;
  border-radius: 20px;
  color: #ffffff;
  padding: 0.4rem;
  width: 10rem;
  height: 2rem;
  font-size: 14px;
  cursor: pointer;
}
.da-dropbtn > img {
  /* margin-bottom: -4px; */
  padding: 4px;
  margin-left: 10px;
}
.da-dropdown-content {
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.da-dropdown:hover .da-dropdown-content {
  display: flex;
  flex-direction: column;
}

.da-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.da-dropdown-content a:hover {
  background-color: #ddd;
}
.da-customize {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
  color: #1CA0C4;
}

.da-customize > img {
  margin: 10px;
}
.da-printOrExport {
  display: flex;
  width: 90%;
  justify-content: flex-end;
  color: #1CA0C4;
}
.da-printOrExport > div {
  
  padding: 0.5rem;
  display: flex;
  cursor: pointer;
  margin: 0.4rem;
}
.da-printOrExport > div > img {
  margin-right: 10px;
}
.da-headline {
  font-weight: 900;
  font-size: large;
  line-height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fcfcfc;
  background:#71797E;
  height: 7rem;
}
.daTable {
  display: flex;
  overflow-x: auto;
  /* border: 2px solid black; */
  width: 100%;
}

.da-colHeader {
  color: #000;
  font-size: 14px;
  font-weight: bolder;
  margin-bottom: 1rem;
  margin-top: 1rem;
  margin-left: 1rem;
}
.da-ColContent{
  /* border: 2px solid black; */
  max-width: 100%;
  width: 180px;
  min-width: 180px;
  max-width: 180px;
  display: flex;
  flex-direction: column;
}
.da-colBody {
  margin-left: 1rem;
  padding:1rem;
  width: 180px;
  min-width: 180px;
  max-width: 180px;
  /* margin-top: 1rem; */
  height: 3rem;
  /* border: 2px solid black; */
  color: black;
}
.da-vr {
  overflow: hidden !important;
  border-right: 1px solid #cfcece;
  height: 71vh;
  z-index: 100;
}
.da-pagination-controls {
  z-index: 100;
  margin-top: -4rem;
  display: flex;

  justify-content: end;
}
.da-inner-div-pageController {
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: #fff;
  margin-right: 1rem;
  box-shadow: 2px 2px 2px 2px #4f4e4e;
  width: 30rem;
}
#da-snoCol{
  padding-right: 1rem;
  min-width: 100px;
  /* width: 4rem; */
  /* border: 2px solid black; */
}



#da-height{
  margin-bottom: -18rem;
  position: absolute;
}

.BG1 {
  background: #fcfcfc;
  width: 220%;
  margin-left: 0;
}
.BG2 {
  margin-left: 0;
  width: 220%;
  background: #f9f9f9;
}
