.supercompanyProfile {

	font-family: "Roboto", sans-serif;
	display: grid;
	grid-template-columns: 14% 260%;
	color: #000;
	height: 100vh;
	width: 100%;
	overflow-x: auto;
	overflow-y: auto;
}
.homeRight {
	display: flex;
	flex-direction: column;
}
.sa-vr {
	overflow: hidden !important;
	margin-bottom: -42rem;
	border-right: 1px solid #cfcece;
	height: 100vh;
	z-index: 100;
  }
.supercompanytitleBar {
	font-family: Roboto Serif;
	background-color: #FCFCFC;
	display: flex;
	/* justify-content: space-evenly; */
	align-items: center;
	height: 5rem;
}
.supercompanytitleBar > div {
	/* border: 2px solid black; */
    margin-left: 1rem;
	font-size: 13px;
    margin-right: 1rem;
	display: flex;
	justify-content: flex-start;
	align-items: center; 
    padding-left: 1rem;
    padding-right: 1rem;
	font-weight: 700;
	line-height: 28px;
	color: #1CA0C4;
	width: 10rem;
	height: 2rem;
}
#sNo{
	width: 7rem;
}
.supercompanytitleBar > .supercompanycontainIcons{
	justify-content: center;
}
.row{

    font-family: Roboto Serif;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	/* border: 2px solid black; */
}
.row > div{  
	display: flex;
	margin-left: 1rem;
    margin-right: 1rem;
	/* margin-top: 1rem; */
	padding-left: 1rem;
    padding-right: 1rem;
	font-size: 13px;
	justify-content: flex-start;
	align-items: center; 
	width: 10rem;
	height: 2rem;
	/* border: 2px solid black; */
}
#sNoData{
	width: 7rem;
}
.row > .containIcons{
	cursor: pointer;
	justify-content: center;
}
.odd{
	background-color: #F9F9F9;
}
.even{
	background-color: #FCFCFC;

}
#instanceIdTitle{
	/* border: 2px solid black; */
	width: 14rem;
}
#instanceId{
	/* border: 2px solid black; */
	width: 14rem;
}
.suspended{
	transform: rotate(180deg);
}
.modal-header{
	height: 2rem;
	color: white;
	background-color: #1ca0c4;
	display: flex;
	justify-content: start;
	padding-left: 1rem;
	align-items: center;
}
.modal-container{
	margin-top: 0.5rem;
	/* padding-left: 0.5rem; */
	height: 9rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: black;
	font-weight: bolder;
}
.modal-selectInput{
	border: 2px solid #cfcece;
	margin-top: 1rem;
	margin-left: 2rem;
	width: 10rem;
	height: 2rem;
}
.modal-selectButtons{
	margin-top: 0.5rem;
	display: flex;
	justify-content: center;
	flex-direction: row;
}
.modal-selectButton{
	width: 6rem;
	border-radius: 30px;
	height: 2rem;
}
