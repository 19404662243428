.dashboardProfile{
    font-family: 'Roboto', sans-serif;
    display: grid;
    grid-template-columns: 14% 86%;
    /* position: relative; */
    color: #000;
    /* width: 100%; */
    height: 100vh;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    /* overflow: hidden; */
    overflow-x: hidden;
    overflow-y:hidden;
}
.dashboardRight{
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
}

.Charts{
    display: flex;
    flex-wrap: wrap;
    max-height: 100%;
    height: 100%;
}
.chart-containers{
    padding-bottom: 0.5rem;
    height: 50%;
    width: 50%;
    max-height: 100%;
    max-width: 100%;
    border: 1px dotted #898989
}
.chart-header{
    height: 3rem;
    margin-bottom: 1rem;
    background-color: #5A5959;
    color: white;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: start;
    padding-left: 2rem;
    font-weight: bolder;
}

.chart{
    /* border: 2px solid black; */
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 80%;
}

