.sidebarContainer{
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  background: #71797e;
  color: #e5e5e5;
  overflow: auto;
  /* min-width: 150px; */
}

.sidebarContainer-logo{
  align-self: center;
  width: 140px;
  margin: 1rem auto;
  margin-bottom: 0;
}

  .sidebar{
    /* flex-shrink: 0; */
    color: #e5e5e5;
    height: 100%;
    overflow: auto;
    margin: 0;
    padding: 0;
    margin-top: 60px; 
  }
  
  
.sidebar-item{
    /* padding: .75em 1em; */
    
    display: block;
    transition: background-color .15s;          
    border-radius: 5px;

    min-height: 39px;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 0.5rem 1rem;
  }
  .sidebar-item:hover{
    background-color: rgba(255, 255, 255, .1);
  }
  
  .sidebar-title{
    /* position: relative; */
    display: flex;
    /* font-size: 1.2em; */
    justify-content: space-between;
    /* align-items: center; */
  }
  .sidebarItem-img{
    display: inline-block;
    /* width: 1.5em; */
    width: 18px;
    margin: 0;
    margin-right: 6px;
    margin-bottom: -2px;
    /* position: absolute;
    bottom: 2px; */
    
  }
  .sidebar-title .toggle-btn{
    cursor: pointer;
    transition: transform .3s;
  }
  .sidebar-item.open > .sidebar-title .toggle-btn{
    transform: rotate(180deg);
  }
  .sidebar-content{
    padding-top: .25em;
    height: 0;
    overflow: hidden;
    
  }
  .sidebar-content .sidebar-title{
    /* font-size: 10px; */
  }
  .sidebar-item.open > .sidebar-content{
    height: auto;
    /* font-size: 14px; */
  }
  
  .sidebar-item.plain{
    color: #e5e5e5;
    text-decoration: none;
    min-height: 39px;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 0.5rem 1rem;
  }
  .sidebar-item.plain a{
    /* display: inline-block; */
  }
  .sidebar-item.plain:hover{
    /* text-decoration: underline; */
    cursor: pointer;
  }
  .sidebar-item.plain i{
    display: inline-block;
    
  }